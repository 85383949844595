<template>
  <div id="a-test-container">
    <div id="a-test-title">
      <Menubar />
    </div>
    <Timer :isActive="false" :staticTimer="selectedTest.time" />
    <div id="a-test-content">
      <div class="row self-center">
        <div id="titulo">
          <h1>{{ selectedTest.descriptionVideo }}</h1>
          <!-- <video
          v-if="selectedTest.video"
          @onEnded="videoEnded"
          :videoURL="selectedTest.video"
        /> -->
        </div>
        <div id="posicion">
          <YouTube
            id="a-video"
            src="https://youtu.be/rvULu1tONh8"
            @ready="onReady"
            ref="youtube"
          />
        </div>
        <div id="pdf">
          si prefiere descargar el pdf da clic &nbsp;
          <router-link target="_blank" to="/INDUCCION PAGINA.pdf">
            aqui</router-link
          >
        </div>
      </div>
      <div id="a-videopage-arrows">
        <button @click="onPrev" class="a-page-buttons">
          <div id="a-rounded-icon-color-btn">
            <img :src="require('@/assets/icons/arrow_left.svg')" />
          </div>
          <span>Atrás</span>
        </button>
        <button @click="onNext" class="a-page-buttons">
          <div id="a-rounded-icon-color-btn">
            <img :src="require('@/assets/icons/arrow_right.svg')" />
          </div>
          <span>Siguiente</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Menubar from "@/components/navigation/Menubar.vue";
// import Video from "@/components/multimedia/Video.vue";
import YouTube from "vue3-youtube";
import Timer from "@/components/timer/Timer.vue";
import { mapState } from "vuex";
export default {
  name: "VideoPage",
  components: {
    Menubar,
    // Video,
    YouTube,
    Timer,
  },
  data() {
    return {
      isVideoEnded: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("test", ["selectedTest"]),
  },
  methods: {
    onPrev() {
      this.$router.push({ name: "Home" });
    },
    onNext() {
      this.$router.push({ name: "TestPage" });
    },
    videoEnded() {
      this.isVideoEnded = true;
    },
    onReady() {
      this.$refs.youtube.playVideo();
    },
  },
};
</script>

<style>
.start-button {
  display: flex;
}

#a-rounded-icon-color-btn {
  margin-right: 10px;
}
</style>

<style scoped>
#a-videopage-arrows {
  display: flex;
  height: 50px;
  justify-content: space-between;
}
#titulo {
  display: flex;
  justify-content: center;
}
#posicion {
  position: absolute;
  top: 35%;
  left: 35%;
  display: flex;
  justify-content: center;
}
#pdf {
  position: absolute;
  top: 70%;
  left: 35%;
  display: flex;
  justify-content: center;
}
#a-video {
  position: relative;
  height: 80%;
  width: 60%;
}

#a-videopage-arrows a {
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 820px) {
  #posicion {
    top: 35%;
    left: 5%;
    display: flex;
    justify-content: center;
  }
  #pdf {
    top: 75%;
    left: 5%;
    display: flex;
    justify-content: center;
  }
}
</style>
