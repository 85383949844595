<template>
  <div class="a-timer">
    <img :src="require('@/assets/images/slider/slider_title.svg')" />
    <hr />
    <div class="a-text-timer">
      <span class="a-minutes-text">{{ timeToShow }}</span>
    </div>
  </div>
  <div
    v-if="isActive"
    id="a-progress-container"
    :class="isTimerDanger ? 'a-timer-danger' : ''"
  >
    <ProgressBar :value="timePercentage" :showValue="false" />
  </div>
</template>

<script>
const TEN_MINUTES = 60;
export default {
  name: "Countdown",
  props: {
    isActive: Boolean,
    staticTimer: {
      type: Number,
      default: TEN_MINUTES,
    },
  },
  data() {
    return {
      timer: TEN_MINUTES,
    };
  },

  emits: ["onTimeEnded"],
  watch: {
    isActive(value) {
      if (value) {
        this.initializeTimer();
      }
    },
  },
  computed: {
    timeToShow() {
      let timeConverted = this.secondsToHoursMinutesSeconds(
        parseInt(this.timer)
      );
      let hours =
        timeConverted["hours"] > 9
          ? timeConverted["hours"]
          : `0${timeConverted["hours"]}`;
      let minutes =
        timeConverted["minutes"] > 9
          ? timeConverted["minutes"]
          : `0${timeConverted["minutes"]}`;
      let seconds =
        timeConverted["seconds"] > 9
          ? timeConverted["seconds"]
          : `0${timeConverted["seconds"]}`;
      return `${hours}:${minutes}:${seconds}`;
    },
    timePercentage() {
      var totalTime = this.staticTimer * 60;
      var timeleft = this.timer;

      return (timeleft * 100) / totalTime;
    },
    isTimerDanger() {
      return this.timePercentage < 10 ? true : false;
    },
  },
  methods: {
    secondsToHoursMinutesSeconds(seconds) {
      return {
        hours: Math.trunc(seconds / 3600),
        minutes: Math.trunc((seconds % 3600) / 60),
        seconds: Math.trunc((seconds % 3600) % 60),
      };
    },
    initializeTimer() {
      if (this.isActive) {
        setInterval(() => {
          if (this.timer > 0) {
            this.timer -= 1;
          } else if (this.timer == 0) {
            this.$emit("onTimeEnded");
          }
        }, 1000);
      }
    },
  },
  mounted() {
    this.timer = this.staticTimer * 60;
  },
};
</script>

<style scoped>
.a-timer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.a-timer img {
  height: 60px;
  object-fit: contain;
}

.a-timer hr {
  margin: 10px;
  height: 100%;
}

.a-timer .a-text-timer {
  display: flex;
  align-items: flex-end;
}
.a-timer .a-text-timer .a-minutes-text {
  color: var(--color-2);
  font-family: Quicksand;
  font-size: 91px;
  font-weight: bold;
  margin: 0 20px;
}
.a-timer .a-text-timer .a-units-text {
  font-family: Quicksand;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.7;
  letter-spacing: -2.5px;
  text-align: left;
  color: var(--color-3);
}
#a-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5px;
}
</style>
